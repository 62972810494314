
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Quicksand Light"), local("Quicksand-Light"),
    url("./fonts/static/Quicksand-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Quicksand Regular"), local("Quicksand-Regular"),
    url("./fonts/static/Quicksand-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Quicksand Medium"), local("Quicksand-Medium"),
    url("./fonts/static/Quicksand-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Quicksand SemiBold"), local("Quicksand-SemiBold"),
    url("./fonts/static/Quicksand-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Quicksand Bold"), local("Quicksand-Bold"),
    url("./fonts/static/Quicksand-Bold.ttf") format("truetype");
}

/* -------------------------------------------------------
Variable font.
Usage:
  html { font-family: 'Quicksand', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Quicksand Variable', sans-serif; }
  }
*/
@font-face {
  font-family: "Quicksand Variable";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("./fonts/variable/Quicksand-VariableFont_wght.ttf") format("truetype");
}